<template>
  <Loader :show="loading"/>
  <HeaderHome />
  <FiltroEvento />
  <div class="container-fluid body">
    <div class="container">
      <div class="row body-box">
        <div class="col-md-8">
          <h1>{{ noticia.title }}</h1>
          <h5>{{ noticia.created_at}} - POR: <b>REDAÇÃO TICKET EXPERIENCE</b> EM <b>{{ noticia.category }}</b></h5>
          <div v-html="noticia.description"></div>
          <Atalhos/>
        </div>
        <div class="col-md-4">
          <div class="row" v-if="noticia.image != ''">
            <div class="col-md-12">
              <img :src="noticia.image" class="img-responsive news-details" >
            </div>
          </div>
          <FacebookSide />
        </div>
      </div>
    </div>
  </div>
  <Newsletter />
  <Footer />
  <FooterEnd />
</template>

<script>
import { defineComponent } from "vue";
import HeaderHome from "../components/HeaderHome";
import FiltroEvento from "../components/FiltroEvento";
import Footer from "../components/Footer";
import FooterEnd from "../components/FooterEnd";
import Newsletter from "../components/Newsletter";
import FacebookSide from "../components/FacebookSide";
import Atalhos from "@/components/Atalhos";
import Noticia from "@/api/News";
import Loader from "../components/Loader";

export default defineComponent({
  name: "News",
  components: {
    HeaderHome,
    FiltroEvento,
    Footer,
    FooterEnd,
    Atalhos,
    Newsletter,
    FacebookSide,
    Loader
  },
  data() {
    return {
      loading:true,
      noticia: {
        title: "",
        description: "",
      },
      slug: "",
    };
  },
  methods:{
    fetch_news(){
      Noticia.get_news_slug(this.slug).then((request) => {
        if (request.status == 200) {
          this.noticia = request.data;
          this.loading = false;
        } else {
          this.$router.push("/noticia/nao-encontrado/");
        }
      });
    },
  },
  mounted() {
    this.slug = this.$route.params.slug;
    this.fetch_news();
  },
  updated() {
    if(this.slug != this.$route.params.slug){
      this.slug = this.$route.params.slug;
      this.loading = true;
      this.fetch_news();
    }
  }
});
</script>

<style>
</style>
